import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import * as React from 'react';
import { Logo } from './Logo';

export const SubpageHeader: React.FC<{ title: string }> = ({ title }) => {
  const data = useStaticQuery(graphql`
      query SubpageLayoutQuery {
          heroSmall: file(relativePath: { eq: "hero-small.jpg" }) {
              childImageSharp {
                  fluid(maxWidth: 1665, maxHeight: 192, base64Width: 10, fit: COVER) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `);

  return (
    <>

      <nav id="header" className="absolute w-full top-0 text-white bg-transparent" style={{ zIndex: 2 }}>
        <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
          <div className="pl-4 flex">
            <a className="toggleColour no-underline hover:no-underline font-bold text-2xl lg:text-4xl text-gray-800"
               href="/"
            >
              <Logo
                white
                className="h-6 p-1 ml-2"
              />
            </a>
          </div>
        </div>
      </nav>

      {/* Hero */}
      <div
        className="flex flex-col items-center relative h-48 overflow-hidden"
      >
        <div
          className="absolute top-0 walden w-full h-full bg-auto bg-contain flex flex-col items-center"
          style={{ filter: 'brightness(1.1) hue-rotate(-10deg) sepia(.3) saturate(1.6)' }}
        >
          <Img
            className="w-full h-full"
            fluid={data.heroSmall.childImageSharp.fluid}
          />
        </div>
        {title && (
          <div
            className="subpage-header-title absolute top-0 bottom-0 left-0 right-0 container px-3 mx-auto flex flex-wrap flex-row items-center"
          >
            <div className="flex w-full justify-center items-center flex-row">
              <h2 className="text-lg md:text-2xl md:my-4 font-bold leading-tight uppercase text-white inline-block">
                <a href="/">
                  <i className="fa fa-home"></i>&nbsp;
                  <span>Home</span>
                </a>
                &nbsp;/&nbsp;
              </h2>
              <h1
                className="text-xl md:text-4xl md:my-4 font-bold leading-tight uppercase text-white inline-block"
              >
                {title}
              </h1>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
