import * as React from 'react';
import { useUserIsTabbingHook } from '../utils/useUserIsTabbingHook';
import { CdnHelmet } from './CdnHelmet';
import { Footer } from './Footer';
import { SubpageHeader } from './SubpageHeader';

export const SubpageLayout = ({ children, title }) => {
  useUserIsTabbingHook();

  return (
    <div className="leading-normal tracking-normal mx-auto">
      <CdnHelmet/>
      <SubpageHeader title={title}/>
      <div className="mx-auto container px-4 pt-24 lg:w-4/5 mb-20">
        {children}
      </div>
      <Footer/>
    </div>
  );
};
