import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import Helmet from 'react-helmet';

type Props = {
  description?: string;
  lang?: string;
  meta?: any[];
  keywords?: string[];
  title: string;
};

export const Seo: React.FC<Props> = (props) => {
  const { description, lang, meta, keywords, title } = {
    title: '',
    description: '',
    lang: 'pl',
    meta: [],
    keywords: [],
    ...props,
  };

  const data = useStaticQuery(graphql`
      query DefaultSEOQuery {
          site {
              siteMetadata {
                  title
                  description
                  author
              }
          }
      }
  `);

  const metaDescription = description || data.site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${data.site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: data.site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
              name: `keywords`,
              content: keywords.join(`, `),
            }
            : [],
        )
        .concat(meta)}
    />
  );
};
